import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { classnames, fluidImageWithDefault } from "../../utils/helpers"

import useLocale from "../../hooks/use-locale"
import useInView from "../../hooks/use-in-view"

import Button from "../button/button"
import Image from "../image/image"

import styles from "./mario-day-callout.module.scss"

export default function MarioDayCallout({ locale }) {
  const [ref, inView] = useInView(0.1)
  const { langKey } = useLocale()

  const images = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "global/mar10-day-logo.png" }) {
        childImageSharp {
          fixed(width: 305) {
            ...fluidImageWithDefault
          }
        }
      }
      LogoLarge: file(
        relativePath: { eq: "global/mar10-day-logo-large-up.png" }
      ) {
        childImageSharp {
          fixed(width: 735) {
            ...fluidImageWithDefault
          }
        }
      }
      LogoEs: file(relativePath: { eq: "global/mar10-day-logo-es.png" }) {
        childImageSharp {
          fixed(width: 305) {
            ...fluidImageWithDefault
          }
        }
      }
      LogoLargeEs: file(
        relativePath: { eq: "global/mar10-day-logo-es-large-up.png" }
      ) {
        childImageSharp {
          fixed(width: 735) {
            ...fluidImageWithDefault
          }
        }
      }
      LogoFr: file(relativePath: { eq: "global/mar10-day-logo-fr.png" }) {
        childImageSharp {
          fixed(width: 305) {
            ...fluidImageWithDefault
          }
        }
      }
      LogoLargeFr: file(
        relativePath: { eq: "global/mar10-day-logo-fr-large-up.png" }
      ) {
        childImageSharp {
          fixed(width: 735) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  const logoData = useMemo(() => {
    switch (langKey) {
      case "ca":
        return {
          small: images.Logo.childImageSharp.fixed,
          large: images.LogoLarge.childImageSharp.fixed,
        }
      case "fr":
        return {
          small: images.LogoFr.childImageSharp.fixed,
          large: images.LogoLargeFr.childImageSharp.fixed,
        }
      case "es":
        return {
          small: images.LogoEs.childImageSharp.fixed,
          large: images.LogoLargeEs.childImageSharp.fixed,
        }
      default:
        return {
          small: images.Logo.childImageSharp.fixed,
          large: images.LogoLarge.childImageSharp.fixed,
        }
    }
  }, [langKey, images])

  return (
    <div className={styles.Callout}>
      <div className="wrapper">
        <div
          className={classnames([
            inView && "vp-animate",
            "row row--vertical-center",
            "txt-center",
          ])}
          ref={ref}
        >
          <h2 className="column column--12 column-large--10 margin-bottom-by3">
            <Image
              {...logoData.small}
              alt={locale.logoAlt}
              wrapperClassName={classnames([styles.LogoWrapper, "medium-down"])}
              className={classnames([styles.Logo, "vp-slide vp-slide--pop"])}
            />
            <Image
              {...logoData.large}
              alt={locale.logoAlt}
              wrapperClassName={classnames([styles.LogoWrapper, "large-up"])}
              className={classnames([styles.Logo, "vp-slide vp-slide--pop"])}
            />
          </h2>
          <div className="column column--12 column-large--10">
            <p
              className={classnames([
                styles.Body,
                "vp-slide vp-slide--up vp-delay--2",
                "h6",
              ])}
            >
              {locale.copy}
            </p>
            {locale.cta && (
              <div className="vp-slide vp-slide--down vp-delay-2 margin-top-by4">
                <Button
                  to={locale.cta.url}
                  small={true}
                  arrow={true}
                  color="red"
                >
                  {locale.cta.label}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
