import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { classnames, fluidImageWithDefault } from "../../utils/helpers"
import useInView from "../../hooks/use-in-view"
import useReduceMotion from "../../hooks/use-reduce-motion"

import Image from "../image/image"

import styles from "./mario-day-characters.module.scss"

export default function MarioDayCharacters({ a11yTitle }) {
  const [ref, inView] = useInView(0.1)

  const [rmState] = useReduceMotion()

  const images = useStaticQuery(graphql`
    query {
      mario: file(relativePath: { eq: "home/header/characters/mario.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      luigi: file(relativePath: { eq: "home/header/characters/luigi.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      peach: file(relativePath: { eq: "home/header/characters/peach.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      bowser: file(relativePath: { eq: "home/header/characters/bowser.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      donkeyKong: file(
        relativePath: { eq: "home/header/characters/donkey-kong.png" }
      ) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      toad: file(relativePath: { eq: "home/header/characters/toad.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      yoshi: file(relativePath: { eq: "home/header/characters/yoshi.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      className={classnames([
        styles.Header,
        rmState === 1 && styles["reduced-motion"],
      ])}
      ref={ref}
    >
      {a11yTitle && <h1 className="visually-hidden">{a11yTitle}</h1>}

      <div className="row">
        <div className={classnames(["column column--8 column-large--12"])}>
          <div className={classnames([styles.ImageGroup, "image-group"])}>
            <Image
              {...images.mario.childImageSharp.fixed}
              alt="Mario"
              className={classnames([
                styles.Character,
                styles.Mario,
                inView && styles.Animate__Mario,
                rmState === 1 && styles["reduced-motion"],
                "image-group__item",
              ])}
            />
            <Image
              {...images.luigi.childImageSharp.fixed}
              alt="Luigi"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.Luigi,
                "image-group__item",
              ])}
            />
            <Image
              {...images.peach.childImageSharp.fixed}
              alt="Peach"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.Peach,
                "image-group__item",
              ])}
            />
            <Image
              {...images.bowser.childImageSharp.fixed}
              alt="Bowser"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.Bowser,
                "image-group__item",
              ])}
            />
            <Image
              {...images.donkeyKong.childImageSharp.fixed}
              alt="Donkey Kong"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.DonkeyKong,
                "image-group__item",
              ])}
            />
            <Image
              {...images.toad.childImageSharp.fixed}
              alt="Toad"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.Toad,
                "image-group__item",
              ])}
            />
            <Image
              {...images.yoshi.childImageSharp.fixed}
              alt="Yoshi"
              className={classnames([
                styles.Character,
                inView && styles.Animate__Character,
                styles.Yoshi,
                "image-group__item",
              ])}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
