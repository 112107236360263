import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BladeDecoration from "../../components/blade-decoration/blade-decoration"
import VideoInline from "../../components/video-inline/video-inline"

import useInView from "../../hooks/use-in-view"

import { classnames } from "../../utils/helpers"

import styles from "./home-video.module.scss"
import Button from "../../components/button/button"
import useTracking from "../../hooks/use-tracking"

export default function HomeVideo({ locale, className }) {
  const images = useStaticQuery(graphql`
    query {
      videoButton: file(relativePath: { eq: "home/video/video-button.jpg" }) {
        childImageSharp {
          fixed(width: 1200) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  const { trackExitLink } = useTracking()
  const [ref, isInView] = useInView()

  return (
    <div
      className={classnames([
        styles.Video,
        isInView && "vp-animate",
        className,
      ])}
      ref={ref}
    >
      <BladeDecoration type="wave" backgroundColor="red" />
      <div className="wrapper txt-center">
        <VideoInline
          className="vp-slide vp-slide--down"
          id={locale.id}
          name="home-trailer"
          imageProps={
            locale.image
              ? {
                  src: locale.image,
                  alt: locale.imageAlt,
                  visuallyhiddenlabel: locale.videoCtaAlt,
                }
              : images.videoButton.childImageSharp.fixed
          }
          trackPrefix="home"
          trackValue="featured trailer"
        />
      </div>
      <div className="row txt-center">
        <div className="column column--12 column-large--10">
          <h2 className="h5 margin-bottom-by1 margin-top-by3 vp-slide vp-slide--down vp-delay-1">
            {locale.title}
          </h2>
          <p className="vp-slide vp-slide--down vp-delay-2 margin-bottom-by3">
            {locale.description}
          </p>
          <Button
            className="vp-slide vp-slide--down vp-delay-3"
            color="black"
            small={true}
            arrow={true}
            to={locale.cta.to}
            target="_blank"
            rel="noopener nofollow noreferrer"
            onClick={e => {
              trackExitLink(
                {
                  eVars: {
                    39: "home: video: visit site",
                  },
                  events: [53],
                },
                e
              )
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: locale.cta.label }} />
          </Button>
        </div>
      </div>
      <BladeDecoration type="wave" flip={true} />
    </div>
  )
}

HomeVideo.propTypes = {
  locale: PropTypes.object,
  className: PropTypes.string,
}
