import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./home-switch-cta.module.scss"

import { classnames } from "../../utils/helpers"

import useInView from "../../hooks/use-in-view"

import Button from "../../components/button/button"
import Image from "../../components/image/image"

export default function HomeSwitchCta({ locale }) {
  const [ref, inView] = useInView(0.1)

  const image = useStaticQuery(graphql`
    query {
      Switch: file(relativePath: { eq: "home/switch-cta/switch-callout.png" }) {
        childImageSharp {
          fixed(width: 504) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div className="wrapper">
      <div
        className={classnames([
          inView && "vp-animate",
          styles.Container,
          "row",
          "row--vertical-center",
        ])}
        ref={ref}
      >
        <div className="column column--12 column-large--6">
          <Image
            {...image.Switch.childImageSharp.fixed}
            className={classnames([styles.Image, "vp-slide", "vp-slide--pop"])}
          />
        </div>
        <div
          className={classnames([
            "column column--12 column-large--6",
            styles.ContentSpacing,
          ])}
        >
          <h2 className="h3 vp-slide vp-slide--down">{locale.title}</h2>
          <p className="vp-slide vp-slide--down vp-delay-2">{locale.copy}</p>
          <div className="vp-slide vp-slide--down vp-delay-4">
            <Button to={locale.cta.url} arrow={true} small={true} color="red">
              {locale.cta.label}
              {locale.cta.hiddenText && (
                <span className="visually-hidden">{locale.cta.hiddenText}</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeSwitchCta.propTypes = {
  locale: PropTypes.object,
}
